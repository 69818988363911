import { useSubscription, gql } from '@apollo/client';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Countdown from '../Countdown/Countdown';
import Icon from '../Icon/Icon';
import Odds from './Odds';
import Competitor from '../Competitor/Competitor';
import * as styles from './Race.module.css';

export default function Race({
  url,
  meeting,
  number,
  startTime,
  competitors = [],
  id,
}) {
  const [prices, setPrices] = useState(null);

  const { data, loading } = useSubscription(
    gql`
      subscription priceUpdates($id: ID!, $priceCode: inputSourceType!) {
        priceUpdates(raceId: $id, sourceTypes: [$priceCode]) {
          updated
          prices {
            tabNo
            price
            type
          }
        }
      }
    `,
    {
      variables: {
        id,
        priceCode: { type: 'WIN', source: process.env.GATSBY_PRICE_CODE },
      },
      fetchPolicy: 'no-cache',
      shouldResubscribe: true,
    }
  );

  useEffect(() => {
    if (!loading && data) {
      setPrices(data?.priceUpdates?.prices);
    }
  }, [loading, data]);

  const { type, track, externalId } = meeting;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Icon name={type} />
        <h3 className={styles.title}>{`R${number} ${track.name}`}</h3>
        <div className={styles.timeContainer}>
          <Countdown time={startTime} />
          <span>{dayjs(startTime).tz().format('h:mma')}</span>
        </div>
      </div>
      <ul className={styles.competitors}>
        {competitors.map((competitor) => (
          <li key={competitor.tabNo}>
            <Competitor
              competitor={competitor}
              externalId={externalId}
              raceNumber={number}
            >
              <Odds
                url={url}
                prices={
                  prices && prices?.find((p) => p.tabNo === competitor.tabNo)
                    ? [prices?.find((p) => p.tabNo === competitor.tabNo)]
                    : competitor.prices
                }
                type="WIN"
              />
            </Competitor>
          </li>
        ))}
      </ul>
    </div>
  );
}

Race.propTypes = {
  url: PropTypes.string,
  meeting: PropTypes.shape(),
  number: PropTypes.number,
  startTime: PropTypes.string,
  competitors: PropTypes.arrayOf(PropTypes.shape()),
  id: PropTypes.string,
};
