import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import DefaultSilkImg from './default_silk.png';
import * as styles from './Silk.module.css';

export default function Silk({ externalId, raceNumber, competitor }) {
  return (
    <div className={styles.silkContainer}>
      {competitor.barrier ? (
        <div
          className={clsx(
            styles.barrier,
            styles[`number${competitor.barrier}`]
          )}
        >
          <span>{competitor.barrier}</span>
        </div>
      ) : null}
      <div className={styles.silk}>
        <img
          className={styles.silkSpritesheet}
          src={`${process.env.GATSBY_SILKS_URL}/${externalId}_${raceNumber}_SPRITE_32x32.png`}
          alt={competitor.name}
          style={{ left: `-${((competitor.tabNo - 1) * 32) / 10}rem` }}
          onError={(event) => {
            // eslint-disable-next-line no-param-reassign
            event.target.src = DefaultSilkImg;
          }}
        />
      </div>
    </div>
  );
}

Silk.propTypes = {
  externalId: PropTypes.string.isRequired,
  raceNumber: PropTypes.number.isRequired,
  competitor: PropTypes.shape().isRequired,
};
